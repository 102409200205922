.kantar-table {
    border: none;
    width: 100%;
    border-collapse: collapse;
    font-size: 0.8rem;
}
.kantar-table tbody th {
    border: 1px solid #bebebe;
    text-align: left;
    padding: 5px;
    font-weight: bold;
    background-color: rgb(65, 145, 195);
    color: white;
}

.kantar-table tbody tr td {
    border: 1px solid #bebebe;
    text-align: left;
    padding: 5px;
}

.kantar-table tbody tr td.header {
    font-weight: bold;
    background-color: #eee;
}

.kantar-table tr.is-array:nth-child(odd) {
    background-color: #dce4ec;
}

.kantar-table tr.is-array:nth-child(even) {
    background-color: white;
}

.kantar-nested-table tr.is-array:nth-child(odd){
    background-color: lightgrey;
}