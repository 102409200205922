ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    border: 1px solid #2F404F;
    background-color: #2F404F;
}

li {
    float: left;
}

li a {
    display: block;
    color: #FFF;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
}

li a:hover, .active {
    background-color: #253342;
    color: #ff7a59;
}
