.mismatches-wrapper {
    display: flex;
    justify-content: space-between;
}

.mismatches-search-button {
    background-color: #ff7a59;
    border: 0.5px solid #ff7a59;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    color: white;
    padding: 10px 15px 10px 20px;
    text-align: center;
    text-decoration: none;
    font-size: 14px;
    z-index:1000;
    cursor: pointer;
    display: flex;
}

.mismatches-search-button:hover {
    background-color: #FFF;
    color: #ff7a59;
    border: 0.5px solid #ff7a59;
}

.mismatches-search-button .mismatches-search-icon {
    padding-right: 5px;
}

.mismatches-search {
    margin: 0 auto;
    margin-top: 30px;
    display: flex;
    justify-content: center;
}

.basic-slide {
    display: inline-block;
    width: 215px;
    padding: 10px 15px;
    font-weight: 400;
    background: #FFF;
    border: 0.5px solid #dce4ec;
    outline: 0;
}

.basic-slide::-webkit-input-placeholder {
    color: #aaa;
    text-indent: 0;
    font-weight: 300;
}

.basic-slide-label {
    display: inline-block;
    top: 0;
    left: 0;
    padding: 10px 15px;
    background-color: #dce4ec;
    color: #2F404F;
    font-weight: 400;
	font-size: 14px;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}

.basic-slide:focus, .basic-slide:active {
    text-indent: 0;
    background: white;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

input:hover, input:active, input:focus {
    border: 1px solid grey;
}

.basic-slide:focus::-webkit-input-placeholder, .basic-slide:active::-webkit-input-placeholder {
    color: #aaa;
}
