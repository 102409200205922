.file-upload-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 20px;
}

.file-upload {
    text-align: center;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 12px;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 500px;
}

.file-upload .file-select {
    border: 2px solid #dce4ec;
    color: #34495e;
    cursor: pointer;
    height: 38px;
    line-height: 38px;
    text-align: left;
    background: #FFFFFF;
    overflow: hidden;
    position: relative;
    flex: 10;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;	
    border: 0.5px solid #dce4ec;
}

.file-upload .file-select .file-select-button {
    background: #dce4ec;
    padding: 0 10px;
    display: inline-block;
    height: 38px;
    line-height: 38px;
    font-size: 14px;
}

.file-upload .file-select .file-select-name {
    line-height: 38px;
    display: inline-block;
    padding: 0 15px;
    color: #aaa;
    font-weight: 400;
    font-size: 14px;
}

.file-upload .file-select:hover {
    border-color: #ff7a59;
    transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -webkit-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
}

.file-upload .file-select:hover .file-select-button {
    background: #ff7a59;
    color: #FFFFFF;
    transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -webkit-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
}

.file-upload.active .file-select {
    border-color: #ff7a59;
    transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -webkit-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
}

.file-upload.active .file-select .file-select-button {
    background: #ff7a59;
    color: #FFFFFF;
    transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -webkit-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
}

.file-upload .file-select input[type=file] {
    z-index: 100;
    cursor: pointer;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    filter: alpha(opacity=0);
}

.file-upload .file-select.file-select-disabled {
    opacity: 0.65;
}

.file-upload .file-select.file-select-disabled:hover {
    cursor: default;
    display: block;
    border: 2px solid #dce4ec;
    color: #34495e;
    cursor: pointer;
    height: 38px;
    line-height: 38px;
    margin-top: 5px;
    text-align: left;
    background: #FFFFFF;
    overflow: hidden;
    position: relative;
}

.file-upload .file-select.file-select-disabled:hover .file-select-button {
    background: #dce4ec;
    color: #666666;
    padding: 0 10px;
    display: inline-block;
    height: 38px;
    line-height: 38px;
}

.file-upload .file-select.file-select-disabled:hover .file-select-name {
    line-height: 38px;
    display: inline-block;
    padding: 0 10px;
}

.file-upload-button {
    background-color: #ff7a59;
    border: 0.5px solid #ff7a59;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;	
    height: 40px;
    color: white;
    padding: 10px 0px 10px 28px;
    text-align: center;
    text-decoration: none;
    font-size: 14px;
    z-index:1000;
    flex: 1;
    cursor: pointer;
    display: flex;
}

.file-upload-button:hover {
    background-color: #FFF;
    color: #ff7a59;
    border: 0.5px solid #ff7a59;
}

.file-upload-button:disabled {
    background-color: rgb(206, 206, 206);
    border: 0.5px solid rgb(206, 206, 206);
    pointer-events: none;
}

.file-upload-button .file-upload-icon {
    padding-right: 5px;
}
