.App {
    font-family: Helvetica, Arial, sans-serif;
    font-size: 14px;
    text-align: left;
    margin: 10px;
}

.loader-wrapper {
    text-align: center;
    padding-top: 20px;
    padding-bottom: 60px;
}

.loader {
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #ff7a59; /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
    margin: auto;
}

.loader-label {
    margin-top: -85px;
}
  
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
